export const headerBtn = [
  {
    text: 'SIGN UP',
    class: 'btn-orange',
    subDec: 'FOR NEWS',
    onclick: () =>
      (window.location.href =
        'https://www.gardenofdreamsfoundation.org/sign-up'),
  },
]

export const DONATE_ROUTE = 'https://www.classy.org/give/597491/#!/donation/checkout';
