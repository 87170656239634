import React, { useEffect } from 'react'
import Router from './router'
import { CategoryContextProvider } from './context/Context'
import { MenuContextProvider } from './context/MenuContext'
import { ArticleContextProvider } from './context/ArticleContext'
import './style/style.min.css'
import './App.scss'
import './style/style.scss'
import './style/cms.scss'
import './style/ticketdonations.scss'
import './style/programs.scss'
import './style/whoweare.scss'
import './style/dreamnotes.scss'
import './style/blog.scss'
import './style/getInvolved.scss'
import './style/button.scss'
import './style/herobanner.scss'
import './style/carousel.scss'
import { StoryContextProvider } from './context/StoryContext'
import { HelmetProvider } from 'react-helmet-async'
import { trackPageView } from './helper/utils';

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top when component mounts
  }, [])

  return null // No need to render anything
}

function App() {

  useEffect(() => {
    const brandName = "madison_square_garden";
    const businessUnit = "garden_of_dreams";
    const pageType = window.location.pathname.replace("/", "");
    const clickPreviousPv = document.referrer || "null";
  
    trackPageView(brandName, businessUnit, pageType, clickPreviousPv);
    const handleRouteChange = () => {
      const updatedPageType = window.location.pathname.replace("/", "");
      trackPageView(brandName, businessUnit, updatedPageType, document.referrer || "null");
    };

    window.addEventListener("popstate", handleRouteChange);
    window.addEventListener("pushstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
      window.removeEventListener("pushstate", handleRouteChange);
    };
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0) // Scroll to top when component mounts
  // }, [])
  return (
    <HelmetProvider>
      <StoryContextProvider>
        <ArticleContextProvider>
          <CategoryContextProvider>
            <MenuContextProvider>
              <div className='App'>
                <ScrollToTop />
                <Router />
              </div>
            </MenuContextProvider>
          </CategoryContextProvider>
        </ArticleContextProvider>
      </StoryContextProvider>
    </HelmetProvider>
  )
}

export default App
